import React, {Component} from 'react';
import "./Tehnicka.css"

const mainImg = require('./tehnicka.jpg');

class Tehnicka extends Component{
    render(){
        return(
            <div className='MainContainer'>
            <p></p>
            <p></p>
            <h1>TEHNIČKA ZAŠTITA</h1>
            <p></p>
            <img className="Header-img"  src={mainImg}></img>
           <p></p>
           <p></p>
            Video nadzor<br></br>
              Protivprovalni sistemi<br></br>
              Vatrodojavni sistemi<br></br>
            Sefovi, sigurnosne kase i ormari<br></br>
            Kontrola prolaza i radnog vremena <br></br>
            Interfonski sistemi<br></br>
            Automatika za vrata, kapije i sisteme kontrole i naplate parkinga <br></br>
            GPS praćenje vozila 
            <p></p>
            </div>
        )
    }
};
export default Tehnicka;