import React from 'react'
import './basetemp.css'




import bimage1 from './images/image1.jpg';
import bimage2 from './images/image2.jpg';
import bimage3 from './images/image3.jpg';
import bimage4 from './header_stranice.jpg';




 const BaseTemp = (props) => {

    return (
    <div>
        <div className="Hwrapper">
            <div className='content-img' >
                <img src={bimage1}/>
                <img src={bimage2}/>
                <img src={bimage3}/>
                <img src={bimage4}/>
              
            </div>
            <div className="Cname">
                <h1>SECURITY</h1>
            </div>


        </div>
        <div className="container">
            <div className="main-content">
                {props.content}
                </div>
          
    
        </div>
        </div>

    )
}
export default BaseTemp;