import './../bootstrap.css'
import React, {Component} from 'react';
import "./MyAppBody.css";
import LoginButton from '../myAppHeader/LoginButton'
import YoutubeEmbed from './YoutubeEmbend';
const mainImg = require('./header_stranice.jpg');




class MyAppBody extends Component{
  constructor(props){
  super(props) 

  this.state = {
    fileUploadState:""
    }
}

fileUploadButton = ()=>
{
    document.getElementById('real-file').click();
    document.getElementById('real-file').onchange = () =>
    {   
this.setState({fileUploadState:document.getElementById('real-file').value});
    }
    }

  render(){
        return(

            <div class="main">

  <div class="text text-justify">
<p></p>
<h1>Mi Brinemo Za Vas</h1>
  <h3 class="text-center">
Naš tim specijaliziranih tehničara će u roku od 24h osigurati vas i Vašu imovinu najkvalitetnijim sitemima alarma ili video nadzora . Besplatno vršimo procjenu ugroženosti i cijenu usluga prilagođavamo Vašim mogućnostima!</h3>
<br></br>
<YoutubeEmbed embedId="LOqRi3zVrTA" />

  <h1 class="text-center">Više informacija o našim uslugama</h1>
 
</div>
 <a href="/Services" ><button type="button" class="btn btn-secondary btn-lg text-center">Više!</button></a>
</div>
    
        );
    }
}




export default MyAppBody;