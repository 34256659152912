import React, {Component} from 'react';
import "./Tehnicka.css"

const mainImg = require('./fizicka.jpg');

class Fizicka extends Component{
    render(){
        return(
            <div className='MainContainer'>
                <p></p>
                <h1>FIZIČKA ZAŠTITA</h1>
                <p></p>
                <img className="Header-img"  src={mainImg}></img>
                <p></p>
                Fizička zaštita <br></br>Obezbjeđenje VIP lica - Body guard<br></br>
                Obezbjeđenje objekata<br></br>
                Obezbjeđenje javnih skupova
            </div>
        )
    }
};
export default Fizicka