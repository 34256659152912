import React, {Component} from 'react';
import "./Tehnicka.css"

const mainImg = require('./dojavni.jpg');

class Dojavni extends Component{
    render(){
        return(
                <div className='MainContainer'>
                <p></p>
                <h1>DOC</h1>
                <p></p>
                <img className="Header-img"  src={mainImg}></img>
                <p></p>
                U okviru BB Gard Security uspostavljen je DOC (Dojavno Operativni Centar) iz koga se neprestano nadgleda funkcioniranje i ispravnost ugrađenih sistema tehničke zaštite, uz interakciju sa korisnicima naših usluga, a u slučaju nepoželjnih događaja intervencijom
                <p><h2>Zaštita bez zaštitara na objektu</h2>
Kontinuirano praćenje video nadzora iz DOC-a
Video nadzor po alarmnom događaju<br></br>
Praćenje perimetra</p>
            </div>
        )
    }
};
export default Dojavni;