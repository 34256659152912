
import './MyAppHeader.css';
import React, {Component} from 'react';
import MyAppBurgerMenu from './MyAppBurgerMenu.jsx'
import LoginButton from './LoginButton.jsx'
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router'

class MyAppHeader extends Component {

  constructor(props){
    super(props)
    this.state = {
      width:  1000
    }
  }

render(){
const logo = require('./bbgard.png');
 // const [width,] = this.useWindowSize();

    if(this.state.width>=960)
    return(
      
     <div className="Background">
       <img className="Logo" src={logo} alt="logo"></img>

     <div className="list">
      <li className="nav-item">
        <Link className="nav-link" to="/About">O nama</Link>
      </li>
      <li className="nav-item">
     <Link className="nav-link" to="/Services">Usluge</Link>
      </li>
      <li className="nav-item">
     <Link className="nav-link" to="/Contact">Kontakt</Link>
        </li>
          <li className="nav-item">
          <Link className="nav-link" to="/">Početna strana</Link>
      </li>   
      <li className="nav-item nav-item-login">
       <Link className="nav-link" to="/login">Register/Log in</Link>
      </li>   

      </div>
      </div>
      
    )
      else
     return(
      <div className="Background" id="Background-mini">
      <img  id="logo-mini" src={logo} alt="logo"></img>
      <MyAppBurgerMenu></MyAppBurgerMenu>


   

      </div>
     );
  }
 
  /**
   * Calculate & Update state of new dimensions
   */
  updateDimensions() {
 
  //  if(window.innerWidth < 500) {
 //     this.setState({ width: 450, height: 102 });
 //   } else {
      let update_width  = window.innerWidth;
    //  let update_height = Math.round(update_width/4.4);
      this.setState({ width: update_width});
    }
  
    /**
   * Add event listener
   */
  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions.bind(this));
    this.updateDimensions();
  }

  /**
   * Remove event listener
   */
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }
}
  export default withRouter(MyAppHeader);

   