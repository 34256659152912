import React, {Component} from 'react';
import "./Contact.css";
import {Phone ,EnvelopeOpen
} from "react-bootstrap-icons"
import facebook from "./images/facebook.png"
import instagram from "./images/instagram.png"




class Contact extends Component{
    render(){
        return(
            <div className='MainContainer'>
                <div>
                <h1 className="m-5 ">KONTAKT</h1>
                <div className="ml-5 mt-5 contact-wrapper">
                    <div className='d-flex'>
                    <Phone className="d-flex mt-2" size={40}/>
                    <ul className="d-flex flex-column pl-3">
                    <li className="d-flex  font-weight-bolder font-size-20">Tel:  <strong class="ml-2">+387(0)62770848</strong></li>
                    <li className="d-flex  font-weight-bolder font-size-20">Tel: <strong class="ml-2"> +387(0)61807624</strong></li>



                    </ul>
                    </div>
                    <div className='d-flex'>
                    <EnvelopeOpen className="d-flex mt-2" size={40}/>
                    <ul className="d-flex flex-column pl-3">
                    <li><br></br></li>
                    <li className="d-flex  font-weight-bolder">E-mail: <strong class="ml-2">bbgard1@gmail.com</strong></li>
                    </ul>
                    </div>
                  
                </div>
            </div>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2867.355704073831!2d17.446547515772505!3d44.055363079109526!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475fa689f6ceb96f%3A0x9b080f5180fa7073!2sBB%20Gard%20Security%20d.o.o.!5e0!3m2!1sen!2sba!4v1642587591383!5m2!1sen!2sba"
                 width="600" height="450" style={{border:"0"}} allowfullscreen="" loading="lazy"></iframe>
            </div>
        )
    }
}
export default Contact;