import React, { Component } from 'react';
import "./OurServices.css"
import OurServicesItem from './OurServicesItem.jsx'
import { useHistory } from 'react-router-dom';



    class OurServices extends Component{

    constructor(props){
        super(props)
        this.array = this.array.bind(this);
        this.a =[2,3,4,5]
    }
   array(){
   return  this.a;

}

    render(){
      
        return(
        
            <div>
                
        <p>{this.array}</p>
          

           
         

             </div>
        )
    
    }
};
export default OurServices;