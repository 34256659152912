import React, { Component } from "react";
import './LoginPage.css';
import {Formik, Form, Field, ErrorMessage} from 'formik';

class LoginPage extends Component{
    constructor(props){
        super(props);
        this.submit = this.submit.bind(this);
        this.validate = this.validate.bind(this);
    }

    submit(values){ //kad se iz formika zovne on submit on posalje objekt s parametrima 
        //i keyevima iz forme recimo description:"vrjednost iz inputa"
        // let username = AuthenticationService.getLoggedinUserName();


// if (this.state.id === -1) {
// TodoDataService.createTodo (username,todo)
// .then(() => this.props.history.push('/todos'))
// } else {
// TodoDataService.updateTodo(username, this.state.id, todo)
//     .then(() => this.props.history.push('/todos'))

// }
}
validate(values){
    
let errors ={}; // ako ima nesto u ovom objektu printace ga u Error messge u formiku
if(!values)
errors.username = 'fill the form'
 if(values.password.length<5)
errors.password = 'Enterdpassword should have atlest 5 characters'
// if(values.username.length<5)
// errors.username = 'Enterd username should have atlest 5 characters'

 if(values.name.length<1)
errors.name = 'enter name'
if(values.surname.length<1)
errors.surname = 'enter surname'


return errors;
}

    render(){
        return(
            <div>
               
            <div className="container">
                <Formik initialValues={{ //ovde dajemo vrjednosti formi preko ovog initial value parametra
                   username: "",
                   password: "",
                   name:"",
                   surname:""}} 
                 onSubmit={this.submit}
                    validate={this.validate}
                    validateOnBlur={false //formik po default renderuje on change and blur
                    }
                    validateOnChange={false}
                    enableReinitialize={true} //moras ukljuciti ovo ako hoces da se forma rerenderuje
                    > 
                    {
                        (props) =>(
                            <Form>
                                <div className="Sign-in-login-parent">

                                <div className="Sign-in-Form">
                                <h1 className="Sign-in-header">Sign in</h1>
                                <ErrorMessage name ="username"
                                 component="div" className="alert alert-warning"></ErrorMessage>
                                <fieldset className="form-group">
                                    <label>Chose  Username</label>
                                    <Field className="form-control" type ="text" name ="username"></Field>
                                </fieldset>
                                <ErrorMessage name ="password"
                                 component="div" className="alert alert-warning"></ErrorMessage>
                                <fieldset className="form-group">
                                    <label>Chose password</label>
                                    <Field className="form-control" type="password" name="password"></Field>
                                </fieldset>
                                <ErrorMessage name ="name"
                                 component="div" className="alert alert-warning"></ErrorMessage>
                                <fieldset className="form-group">
                                    <label>Name</label>
                                    <Field className="form-control" type="text" name="name"></Field>
                                </fieldset>
                                <ErrorMessage name ="surname"
                                 component="div" className="alert alert-warning"></ErrorMessage>
                                <fieldset className="form-group">
                                    <label>Surname</label>
                                    <Field className="form-control" type="text" name="surname"></Field>
                                </fieldset>
                                <button className="btn btn-success" type="submit">Sign in</button>
                        </div>
                                <div className="Login-Form">
                                    <h1 className="Log-in-header">Log in</h1>
                                <ErrorMessage name ="username"
                                 component="div" className="alert alert-warning"></ErrorMessage>
                                <fieldset className="form-group">
                                    <label>Username</label>
                                    <Field className="form-control" type ="text" name ="username"></Field>
                                </fieldset>
                                <ErrorMessage name ="password"
                                 component="div" className="alert alert-warning"></ErrorMessage>
                                <fieldset className="form-group">
                                    <label>Password</label>
                                    <Field className="form-control" type="password" name="password"></Field>
                                </fieldset>
                                <button className="btn btn-success" type="submit">Log in</button>
                                </div>

                                </div>
                            </Form>
            
                       )

                    }
                </Formik>
                 </div>
            </div>
        );
    }
}

export default LoginPage 