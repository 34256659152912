import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import MyAppHeader from './myAppHeader/MyAppHeader';
import MyAppBody from './myAppBody/MyAppBody.jsx';
import MyAppFooter from './myAppFooter/myAppFooter.jsx'
import 'bootstrap/dist/css/bootstrap.css'
import LoginPage from './LoginPage.jsx'
import OurServices from './Usluge/OurServices.jsx'
import BaseTemp from './myAppBody/baseTemp'
import ServicesTemp from './myAppBody/servicesTemp'
import Tehnicka from './Usluge/Tehnicka'
import Fizicka from './Usluge/Fizicka'
import Dojavni from './Usluge/Dojavni';
import AboutUs from './myAppBody/AboutUs';
import Contact from './myAppBody/Contact';

import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';

class App extends Component {
  render(){
  return (
    <>
          <Router>
    <MyAppHeader />
    <Route exact path="/"><BaseTemp content={<MyAppBody/>}/></Route>
    <Route exact path="/Services"><ServicesTemp tehnicka={<Tehnicka/>}
    fizicka={<Fizicka/>} dojavni={<Dojavni/>}/></Route>
    <Route exact path="/About"><BaseTemp content={<AboutUs/>}/></Route>
    <Route exact path="/Contact"><BaseTemp content={<Contact/>}/></Route>
      <Switch>
        <Route path="/Services" component={OurServices}></Route>

        <Route path="/login" component={LoginPage}></Route>
      </Switch>
    
    <MyAppFooter />
    </Router>
    </>
  );
  }
}

export default App;
