import React, {Component, useRef} from 'react';
import './MyAppBurgerMenu.css';
import {
  BrowserRouter as Router,
  Link,
  Route,
  Switch,
} from 'react-router-dom';


class MyAppBurgerMenu extends Component{
  constructor(props) {
    super(props);
    this.state = {showList:true}
    this.handleBurgerClick = this.handleBurgerClick.bind(this);
  }

  handleBurgerClick(){
    this.refs.child.willComponentMount(this.state.showList);
  } 

  render(){
    return(
      <div className = "burgerbox">
         <button className="Burger-icon" onClick={this.handleBurgerClick}><svg class="bi bi-list" width="2em" height="2em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" d="M2.5 11.5A.5.5 0 013 11h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5zm0-4A.5.5 0 013 7h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5zm0-4A.5.5 0 013 3h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5z" clip-rule="evenodd"/>
</svg></button>
      <MenuListComponent ref="child"></MenuListComponent>
      </div>
    )
  }
    
}

class MenuListComponent extends Component{
  constructor(props){
    super(props)
    this.state  = {
      showList: false,
      myRef: useRef}
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
   // this.myRef = React.createRef();
  }
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }
  setWrapperRef(node) {
    this.myRef = node;
  }
  handleClickOutside(event) {
    if (this.myRef && !this.myRef.contains(event.target)) {

    this.setState(
       {showList:false}
      )
    } 
  }
  willComponentMount(data){
    this.setState(
      {showList:data}
    )
  }
 render(){
   if(this.state.showList)
   return(
    <div className="burgerListHidden" ref={this.setWrapperRef}>{this.node} >
    <div>
    <a className="burger-link">O NAMA</a>
    </div>
    <div>
    <a className="burger-link" >USLUGE</a>
    </div>
    <div>
    <a className="burger-link">KONTAKT</a>
    </div>
    <div>
    <a className="burger-link">POCETNA STRANA</a>
    </div>
    </div>
   ); else
   return <></>
 }

}

export default MyAppBurgerMenu;