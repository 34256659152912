import './MyAppHeader.css';
import React, {Component} from 'react';
import MyAppBurgerMenu from './MyAppBurgerMenu.jsx'
import {
  BrowserRouter as Routher,
  Link,withRouter, 
} from 'react-router-dom';
import axios from 'axios';

class LoginButton extends Component{
    constructor(props){
      super(props);
      this.goToLoginPage = this.goToLoginPage.bind(this);
    }

   goToLoginPage(){

    console.log("1");
    const product =  {
      productTitle: "kamera",
      productDescription: "sasdasd",
      productImage: "",
    };
    const username = "gard";
    const password = "bbgs1"
    console.log("2");
      //this.props.history.push('/login');
      this.executeAuthentication(username, password).then((response) => {
      this.registerSuccefulLogin(username, response.data.token);
      axios.post(`http://localhost:8080/products/${username}`,product);
      })
       
      
     
      console.log("3");
   } 
   registerSuccefulLogin(username, token){
    sessionStorage.setItem('authenticatedUser', username);
    this.setupInterceptors(this.createJwtToken(token));
   }
   createJwtToken(token){
     return 'Bearer ' + token;
   }
   executeAuthentication(username, password){
     return axios.post('http://localhost:8080/authenticate', {username, password})
   }

   setupInterceptors(token){
    axios.interceptors.request.use(
      (config)=>{
        config.headers.authorization = token;
        return config;
      }
    )
   }
   
   render(){
     return(
    
       
       <button type="button"
              className="btn btn-default logIn"
               onClick={this.goToLoginPage}
               >Register/Log in</button>
     
  
     )
   }
 }

 export default LoginButton