import React, {Component} from 'react';
import "./AboutUs.css"




class AboutUs extends Component{
    render(){
        return(
            <div className='MainContainer'>
                <h1>O Nama</h1>
                <h4>Da biste pravilno izabrali zaštitu za sebe i svoju imovinu ili povjerili svoju sigurnost potrebno je da se najprije upoznate sa Agencijom, poslovnom politikom agencije i načinom rada
BB GARD SECURITY-a.<br></br>
BB GARD SECURITY d.o.o. Bugojno je Agencija za zaštitu ljudi i imovine i nudi široku lepezu usluga tehničke i fizičke zaštite. Dugogodišnje iskustvo, visoka kvaliteta naših usluga, pouzdanost, stručnost, profesionalnost, predanost poslu, praćenje svjetskih trendova u oblasti zaštite, ulaganje u obrazovanje i usavršavanje kadrova, su glavni razlozi zašto da svoju brigu o sigurnosti prepustite nama.<br></br>
BB GARD SECURITY koristi proizvode renomiranih svjetskih proizvođača koji su godinama u vrhu svoje branše kako kvalitetom i tehnološkim dostignućima tako i cijenom. Stalnim napredovanjem i razvojem ove kompanije osiguravaju miran i bezbrižan život, a svojim klijentima BB GARD SECURITY osigurava ono što je najvažnije – sigurnost.</h4>
            </div>
        )
    }
}
export default AboutUs;