//import './../bootstrap.css'
import React, {Component} from 'react';
import "./myAppFooter.css";




   
   
class MyAppFooter extends Component{
    render(){
       return(

    
            <div className="Footer">

            <div className="Left-links-parent">

                <div className="Left-links-header">
                <h3>Linkovi:</h3>
                </div>

                <div className="Left-links">
                    <ul>
                    <a href='#'><li>Facebook</li></a>
                    <a href='#'><li>Instagram</li></a>
                    <a href='#'><li>Ostalo</li></a>
                    </ul>
                    </div>
            </div>


            <div className="Right-links-parent">

                <div className="Right-links-header">
                <h3>Linkovi:</h3>
                </div>

                <div className="Right-links">
                    <ul>
                     <a href='#'><li>LINK1</li></a>
                     <a href='#'><li>LINK2</li></a>
                     <a href='#'><li>LINK3</li></a>
                    </ul>
                    </div>

            </div> 

                 

                <div className="Copy-right text-center">
                <p>© 2020 Copyright</p>        
                </div>
              
                
            </div> 
                
     

    
       )
       
    }
}

export default MyAppFooter;
